/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import '@/assets/sass/style.scss';
import i18n from '@/plugins/i18n'
import Toasted from '@/plugins/toasted'
import middlewares from '@/middlewares/index.js'
require('@/store/subscriber')

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    $eval: param => eval('this.' + param)
  }
});

store.dispatch('auth/attempt', localStorage.getItem('mc_access_token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
});

