import store from '../store'
import router from '../router'
import i18n from '@/plugins/i18n'

export default function termsAndConditionsUserMiddleware(to)
{
  if (store.getters['auth/user'] && store.getters['auth/user'].is_terms_conditions == false && to.name !== 'terms')
  {
    return router.push({
      params: {
        lang: i18n.locale,
      },
      name: 'terms'
    })
  }
}