<template>
  <v-app id="app">
    <component v-bind:is="'style'" type="text/css" lang="scss">
      :root { 
        --primary-color: {{ this.themeConfig ? this.themeConfig.primary_color : "#6bc0a5" }};
        --secondary-color: {{ this.themeConfig ? this.themeConfig.secondary_color : "#A9EAD5" }};
        --avatar-main-colors: {{ this.themeConfig ? this.themeConfig.avatar_main_colors : "#D3ECE4" }};
        --btn-main-color: {{ this.themeConfig ? this.themeConfig.btn_main_color : "#004447" }};
        --search-bar-color: {{ this.themeConfig ? this.themeConfig.search_bar_color :"#F0F9F6" }};
        --table-headers-main-color: {{this.themeConfig ? this.themeConfig.table_headers_main_color : "#007C82" }};
        --progress-bar-primary-color: {{this.themeConfig ? this.themeConfig.progress_bar_primary_color : "#F09267" }};
        --progress-bar-secondary-color: {{this.themeConfig ? this.themeConfig.progress_bar_secondary_color : "#007C82" }};
      }
    </component>

    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  computed: {
    themeConfig: function () {
      return this.$store.getters["auth/user"]
        ? this.$store.getters["auth/user"].theme_config
        : null;
    },
  },

  mounted() {
    this.handleLangSwitching();
  },
  updated() {
    this.handleLangSwitching();
  },

  methods: {
    handleLangSwitching() {
      this.switchBreadcrumbsLang();
      this.switchVuetifyLang();
    },
    switchBreadcrumbsLang() {
      this.$route?.meta?.breadcrumbs?.map((breadcrumb) => {
        breadcrumb.translatedText = this.$i18n.t(breadcrumb.text);

        let breadcrumbWords = breadcrumb.href?.split("/");
        if (breadcrumbWords) {
          if (this.$i18n.locale != breadcrumbWords[1]) {
            breadcrumbWords[1] = this.$i18n.locale;
            breadcrumb.href = breadcrumbWords.join("/");
          }
        }

      });
    },
    switchVuetifyLang() {
      if (this.$vuetify.lang.current != this.$i18n.locale) {
        this.$vuetify.lang.current = this.$i18n.locale.split("_")[0];
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/style.scss">
</style>
