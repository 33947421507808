<template>
  <v-toolbar dense flat no-gutters class="header">
    <router-link :to="{name: 'members-list'}" class="logo">
      <img
        alt="logo"
        :src="
          $store.getters['auth/user']
            ? $store.getters['auth/user'].theme_config.header_logo
            : ''
        "
      />
    </router-link>

    <v-text-field
      :label="`${$t('search')} ${$t('members')}`"
      filled
      dense
      rounded
      @input="serachMembers"
      v-if="$route.name == 'members-list'"
    >
      <template v-slot:prepend-inner>
        <span class="icomoon icon-search"></span>
      </template>
    </v-text-field>

    <v-avatar
      size="48"
      :class="`profile-pic ml-auto ${
        $store.getters['auth/user'].theme_config.primary_color == '#3452FF'
          ? 'white--text'
          : ''
      }`"
      v-if="initials"
    >
      {{ initials }}
    </v-avatar>

    <v-menu offset-y class="ml-2 mr-2" v-if="initials">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="chevron-down ml-2 mr-2 no-background-hover"
        >
          <v-icon large> mdi-chevron-down </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in dropDownItems" :key="index">
          <v-list-item-title
            v-if="item.link != 'logout'"
          >
            <router-link :to="item.link">
              {{ item.title }}
            </router-link>
          </v-list-item-title>

          <v-list-item-title v-else>
            <a @click="logout">
              {{ item.title }}
            </a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dropDownItems: [
        {
          title: this.$t("logout"),
          link: "logout",
        },
      ],
    };
  },

  computed: {
    initials: function () {
      if (this.$store.getters["auth/user"]) {
        let firstLetter =
          this.$store.getters["auth/user"]["first_name"].charAt(0);
        let secondLetter =
          this.$store.getters["auth/user"]["last_name"].charAt(0);

        return firstLetter + secondLetter;
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),

    logout() {
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },

    serachMembers(e) {
      this.$store.dispatch("members/searchMembers", e);
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/header.scss">
</style>