import axios from "axios";
import i18n from "@/plugins/i18n";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    mc_access_token: null,
    user: null,
    user_locale: null,
    checkAutoLogoutIntervID: null,
    checkSessionExpirationIntervID: null,
  },

  getters: {
    authenticated(state) {
      if (state.mc_access_token && state.user) {
        return {
          mc_access_token: state.mc_access_token,
          ...state.user,
        };
      }

      return null;
    },
    user(state) {
      return state.user;
    },
    checkAutoLogoutIntervID(state) {
      return state.checkAutoLogoutIntervID;
    },
    checkSessionExpirationIntervID(state) {
      return state.checkSessionExpirationIntervID;
    },
  },

  mutations: {
    SET_TOKEN(state, mc_access_token) {
      state.mc_access_token = mc_access_token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_LOCALE(state, user_locale) {
      state.user_locale = user_locale;
    },
    SET_CHECK_AUTO_LOGOUT_INTERVAL_ID(state, checkAutoLogoutIntervID) {
      state.checkAutoLogoutIntervID = checkAutoLogoutIntervID;
    },
    SET_CHECK_SESSION_EXPIRATION_INTERVAL_ID(
      state,
      checkSessionExpirationIntervID
    ) {
      state.checkSessionExpirationIntervID = checkSessionExpirationIntervID;
    },
  },

  actions: {
    async login({ state, dispatch }, form) {
      let res = await axios.post("users/dashboard-login", form);

      return await dispatch("attempt", res.data.data[0]?.token.accessToken)
        .then(() => ({ loading: false, errMsg: null }))
        .catch((e) => {
          throw e;
        });
    },

    async keepSignedIn({ dispatch }, form) {
      let res = await axios.post("users/dashboard-keep-signed-in", form);

      dispatch("attempt", res.data.data[0]?.token.accessToken);
    },

    async attempt({ commit, state }, mc_access_token) {
      if (mc_access_token) {
        commit("SET_TOKEN", mc_access_token);
      }

      if (!state.mc_access_token) {
        return;
      }

      try {
        let res = await axios.get("users/account-information");
        let authUser = res?.data?.data[0];

        if (authUser) {
          commit("SET_USER", authUser);

          // switch language
          let userLocale = `${authUser.language_id.language_code}_${authUser.language_id.region_code}`;
          if (i18n.availableLocales.includes(userLocale)) {
            i18n.locale = userLocale;
            commit("SET_USER_LOCALE", userLocale);
          }
        }
      } catch (error) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    logout({ commit, dispatch }) {
      return axios.post("users/logout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        dispatch("clearCheckAutoLogoutIntervals");
      });
    },

    setCheckAutoLogoutIntervalID({ commit }, checkAutoLogoutIntervID) {
      commit("SET_CHECK_AUTO_LOGOUT_INTERVAL_ID", checkAutoLogoutIntervID);
    },

    setCheckSessionExpirationIntervalID(
      { commit },
      checkSessionExpirationIntervID
    ) {
      commit(
        "SET_CHECK_SESSION_EXPIRATION_INTERVAL_ID",
        checkSessionExpirationIntervID
      );
    },

    clearCheckAutoLogoutIntervals({ state, dispatch }) {
      clearInterval(state.checkAutoLogoutIntervID);
      dispatch("setCheckAutoLogoutIntervalID", null);
      clearInterval(state.checkSessionExpirationIntervID);
      dispatch("setCheckSessionExpirationIntervalID", null);
    },
  },
};
