var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"header",attrs:{"dense":"","flat":"","no-gutters":""}},[_c('router-link',{staticClass:"logo",attrs:{"to":{name: 'members-list'}}},[_c('img',{attrs:{"alt":"logo","src":_vm.$store.getters['auth/user']
          ? _vm.$store.getters['auth/user'].theme_config.header_logo
          : ''}})]),(_vm.$route.name == 'members-list')?_c('v-text-field',{attrs:{"label":((_vm.$t('search')) + " " + (_vm.$t('members'))),"filled":"","dense":"","rounded":""},on:{"input":_vm.serachMembers},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"icomoon icon-search"})]},proxy:true}],null,false,3627386215)}):_vm._e(),(_vm.initials)?_c('v-avatar',{class:("profile-pic ml-auto " + (_vm.$store.getters['auth/user'].theme_config.primary_color == '#3452FF'
        ? 'white--text'
        : '')),attrs:{"size":"48"}},[_vm._v(" "+_vm._s(_vm.initials)+" ")]):_vm._e(),(_vm.initials)?_c('v-menu',{staticClass:"ml-2 mr-2",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"chevron-down ml-2 mr-2 no-background-hover"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3041108834)},[_c('v-list',_vm._l((_vm.dropDownItems),function(item,index){return _c('v-list-item',{key:index},[(item.link != 'logout')?_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])],1):_c('v-list-item-title',[_c('a',{on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }