import i18n from '@/plugins/i18n'
import store from '../store'
import router from '../router'

export default function authenticatedUserMiddleware(to)
{
  if (!store.getters['auth/authenticated'] && to.name !== 'login')
  {
    return router.push({
      params: {
        lang: i18n.locale,
      },
      name: 'login'
    })
  }
}