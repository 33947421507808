<template>
  <div class="default h-100">
    <Header />

    <v-main>
      <v-container fluid class="pb-0">
        <router-view />
      </v-container>
    </v-main>

    <Footer />
  </div>
</template>

<script >
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped src="@/assets/sass/layouts/default.scss">
</style>