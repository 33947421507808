import Auth from '@/layouts/Auth.vue';
import i18n from '@/plugins/i18n';

let AuthRoutes = [
    {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
        meta: {
            layout: Auth,
            title: 'login'
        }
    },
    {
        "path": "terms",
        "name": "terms",
        component: () => import(/* webpackChunkName: "terms" */ "@/views/auth/terms.vue"),
        meta: {
            auth: true,
            title: 'terms'
        }
    }
];

export default AuthRoutes;
